/********** Template CSS **********/
:root {
  --primary: #FF3E41;
  --secondary: #51CFED;
  --light: #F8F2F0;
  --dark: #060315;
}

.fw-medium {
  font-weight: 600 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  font-weight: 600;
  transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  position: relative;
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar-light .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: .5s;
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link::before {
      position: absolute;
      content: "";
      width: 0;
      height: 5px;
      top: -6px;
      left: 50%;
      background: #FFFFFF;
      transition: .5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
      width: 100%;
      left: 0;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact::before {
      display: none;
  }

  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
      position: relative;
      min-height: 500px;
  }
  
  .header-carousel .owl-carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .header-carousel .owl-carousel-item h5,
  .header-carousel .owl-carousel-item p {
      font-size: 14px !important;
      font-weight: 400 !important;
  }

  .header-carousel .owl-carousel-item h1 {
      font-size: 30px;
      font-weight: 600;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 45px;
  font-size: 22px;
  transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.page-header {
  background: linear-gradient(rgba(6, 3, 21, .5), rgba(6, 3, 21, .5)), url(./img/carousel-1.webp) center center no-repeat;
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}


/*** About ***/
@media (min-width: 992px) {
  .container.about {
      max-width: 100% !important;
  }

  .about-text  {
      padding-right: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .about-text  {
      padding-right: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .about-text  {
      padding-right: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Feature ***/
@media (min-width: 992px) {
  .container.feature {
      max-width: 100% !important;
  }

  .feature-text  {
      padding-left: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .feature-text  {
      padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .feature-text  {
      padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Service, Price & Team ***/
.service-item,
.price-item,
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.service-item img,
.team-item img {
  transition: .5s;
}

.service-item:hover img,
.team-item:hover img {
  transform: scale(1.1);
}

.service-item a.btn-slide,
.price-item a.btn-slide,
.team-item div.btn-slide {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 0;
}

.service-item a.btn-slide i,
.service-item a.btn-slide span,
.price-item a.btn-slide i,
.price-item a.btn-slide span,
.team-item div.btn-slide i,
.team-item div.btn-slide span {
  position: relative;
  height: 40px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 0 35px 35px 0;
  transition: .5s;
  z-index: 2;
}

.team-item div.btn-slide span a i {
  padding: 0 10px;
}

.team-item div.btn-slide span a:hover i {
  background: var(--secondary);
}

.service-item a.btn-slide span,
.price-item a.btn-slide span,
.team-item div.btn-slide span {
  padding-left: 0;
  left: -100%;
  z-index: 1;
}

.service-item:hover a.btn-slide i,
.price-item:hover a.btn-slide i,
.team-item:hover div.btn-slide i {
  border-radius: 0;
}

.service-item:hover a.btn-slide span,
.price-item:hover a.btn-slide span,
.team-item:hover div.btn-slide span {
  left: 0;
}

.service-item a.btn-slide:hover i,
.service-item a.btn-slide:hover span,
.price-item a.btn-slide:hover i,
.price-item a.btn-slide:hover span {
  background: var(--secondary);
}


/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item {
  position: relative;
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  animation: pulse 1s ease-out .5s;
}

.testimonial-carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  background: var(--primary) !important;
  border: 5px solid var(--light);
  border-radius: 15px;
  transition: .5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--light);
  border-color: var(--primary);
}


/*** Contact ***/
@media (min-width: 992px) {
  .container.contact-page {
      max-width: 100% !important;
  }

  .contact-page .contact-form  {
      padding-left: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .contact-page .contact-form  {
      padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .contact-page .contact-form  {
      padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Footer ***/
.footer {
  background: linear-gradient(rgba(6, 3, 21, .5), rgba(6, 3, 21, .5)), url(./img/map.png) center center no-repeat;
  background-size: cover;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #FFFFFF;
  border-radius: 35px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

/* Carousel */

.carousel-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 80%;
}

.custom-carousel-indicators .carousel-indicators li {
  background-color: red;  /* Change the color to red */
}

.custom-carousel-indicators .carousel-indicators .active {
  background-color: darkred;  /* Change the active indicator to a darker red */
}

.carousel-title div{
  justify-content: center !important;
}

@media only screen and (max-width: 768px) {
  .building h3 {
      font-size: 12px; /* Adjust the size as needed */
  }
  .mylogo{
    height: 80px !important;
  }
}

/* contact button */

.contact-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  text-align: right;
}

.contact-button {
  background-color: #007bff;
  color: white;
  padding: 15px 20px;
  border-radius: 50px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
}

.contact-button a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.contact-options {
  margin-bottom: 10px; /* Space between the main button and options */
}

.contact-option {
  display: block;
  margin-top: 10px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-option:hover {
  background-color: #218838; /* Darker green on hover */
}
/* Table css  */
table {
  width: 90%;
  border-collapse: collapse;
  margin: 20px 0;
}
th, td {
  border: 1px solid #7c5353;
  padding: 8px;
  text-align: center;
}
th {
  background-color: #f2f2f2;
}
table img {
  width: 300px; /* Adjust size as needed */
  height: auto;
}